import './App.css';
import formsDGT from './forms_dgt.json';
import provincias from './provincias.json';
import { MenuProps, verificaNIF} from "./utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
    Checkbox,
    Input,
    MenuItem,
    Select,
    Button,
    NativeSelectRoot,
    NativeSelectField
} from "@chakra-ui/react";
import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { es } from 'date-fns/locale/es';

function App() {
    let navigate = useNavigate();
    const [selectedValue, setSelectedValue] = React.useState('');
    const [selectedProvinceValue, setSelectedProvinceValue] = React.useState('');
    const [formData, setFormData] = React.useState({"Provincia cita": ""});
    const [mandatoryFormData, setmandatoryFormData] = React.useState([]);
    const [showResidencia, setShowResidencia] = React.useState(false);
    const [addCita, setAddCita] = React.useState(false);
    const [datePickerEnabled, setdatePickerEnabled] = React.useState(false);
    const [allMandatoryFilled, setallMandatoryFilled] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    var current_date = new Date();
    const [endDate, setEndDate] = React.useState(new Date(current_date.getFullYear() + 1, current_date.getMonth(), current_date.getDate()));
    const [certificateFile, setCertificateFile] = React.useState(null);
    registerLocale('es', es)

    const pemPublicKey = `
	-----BEGIN PUBLIC KEY-----
	MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmPBQf3n51xedWXL90yCr
	PLeqqZmcYnPiBHfp4URTFK4IQZQu9PSbKGQqroMqNXJ8KQM9S9POnh+xQCJuMRls
	GxMgDWuyS0/rtGyavlHo5twR4TkPCxP8S7zAG5XngEJHy04sMxzx6j7QteAKTtYF
	G/dtVDHkjtjzvzz+Zd0J6OpPPBaR+mywNO7XW85JmVjvQGb6erPQSKB2MhFH1WiZ
	CWw2A6sEoGAQIwwfAyNMeCkXLfLbpru55MR3qewjQiISuck0aiAYlYKURmC99Ryg
	pnQFAegzBgSrjuSHmLat75JZpTXCdQxkZfzPue/d8wYhbLCFcw1oMm+R2LcILyCr
	bwIDAQAB
	-----END PUBLIC KEY-----
	`

    const [importedPublicKey, setImportedPublicKey] = React.useState(null);

    function decodeBase64ToUtf8(base64String) {
        // Decode the Base64 string
        let decodedString = atob(base64String);

        // Convert the decoded string to a UTF-8 string
        let utf8String = decodeURIComponent(escape(decodedString));

        return utf8String;
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setCertificateFile(selectedFile);
    };

    React.useEffect(() => {
        setPaisCanje();
    }, []);

    const toBase64 = (file) => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result.split(",")[1]); // Extract only Base64 part
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const decodeJwtPayload = (token) => {
        const parts = token.split(".");
        if (parts.length !== 3) {
            throw new Error("Invalid JWT format");
        }

        const payloadBase64 = parts[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
    };

    const setPaisCanje = async () => {
        const pemHeader = "-----BEGIN PUBLIC KEY-----";
        const pemFooter = "-----END PUBLIC KEY-----";
        const pemContents = pemPublicKey.trim().replace(pemHeader, "").replace(pemFooter, "");
        const binaryDerString = atob(pemContents);
        const binaryDer = str2ab(binaryDerString);

        setImportedPublicKey(await crypto.subtle.importKey(
            "spki",
            binaryDer,
            {
                name: "RSA-OAEP",
                hash: "SHA-256",
            },
            true,
            ["encrypt"]
        ));

        const defaultData = {"txtIdCitado": "", "txtDesCitado": "", "txtAnnoCitado": "", "txtTelefonoCitado": "", "txtMailCitado": "", "txtUsuarioClave": "", "txtPassClave": "", "txtPassCertificado": "", "txtPaisNac": ""};
        const defaultMandatoryData = ["txtIdCitado", "txtDesCitado", "txtAnnoCitado", "txtTelefonoCitado", "txtMailCitado", "txtPaisNac"];

        const token = searchParams.get("token")
        const payload = decodeJwtPayload(token)

        console.log(payload)

        defaultData["FechaMIN"] = startDate.toLocaleDateString('en-GB')
        defaultData["FechaMAX"] = endDate.toLocaleDateString('en-GB')
        defaultData["Observaciones"] = ""
        defaultData["token"] = token
        defaultData["tipo_bot"] = "ICP_CLAVE"
        defaultData["price"] = payload["price"]
        defaultData["tguser"] = payload["tguser"]
        defaultData["rdbTipoDoc"] = "N.I.E."
        defaultData["txtPassCertificado"] = ""
        defaultData["txtUsuarioClave"] = ""
        defaultData["txtPassClave"] = ""
        setFormData(defaultData);
        setmandatoryFormData(defaultMandatoryData);
    }

    const str2ab = (str) => {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);
        for (let i = 0; i < str.length; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    const encryptString = async (publicKey, data) => {
        const encodedData = str2ab(data);
        const encryptedData = await crypto.subtle.encrypt(
            {
                name: "RSA-OAEP",
            },
            publicKey,
            encodedData
        );
        return encryptedData;
    }

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }

    const handleSubmit = async () => {
        if (validateForm()) {
            // For now, just log the data. In real scenarios, you'd send this to a server.
            console.log(formData);
            if (certificateFile != null) {
                const fileContent = await toBase64(certificateFile);
                formData["certificate_data"] = fileContent
            } else {
                formData["certificate_data"] = ""
            }

            if (formData["txtPassCertificado"] !== "") {
                console.log(importedPublicKey)
                const encryptedData = await encryptString(importedPublicKey, formData["txtPassCertificado"]);
                formData["txtPassCertificado"] = arrayBufferToBase64(encryptedData);
            }

            if (formData["txtPassClave"] !== "") {
                const encryptedData = await encryptString(importedPublicKey, formData["txtPassClave"]);
                formData["txtPassClave"] = arrayBufferToBase64(encryptedData);
            }

            fetch('https://api.notifybotstg.com/api/insert_new_tg_appointment', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    navigate('success');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    navigate('error');
                });
        }
    };

    const validateForm = () => {
        var fulfilled = true;
        console.log(formData)

        if (formData["txtPaisNac"] === "Seleccionar") {
            fulfilled = false
        }

        mandatoryFormData.forEach(field => {
            console.log(field)
            if ((formData[field] === '') || (Array.isArray(formData[field]) && formData[field].length == 0)) {
                fulfilled = false;
                setallMandatoryFilled(true)
            }
        });

        fulfilled = fulfilled && (formData["rdbTipoDoc"] === "PASAPORTE" || verificaNIF(formData["txtIdCitado"]));

        return fulfilled;
    };

    const handleDropdownChange = (event) => {
        //setShowResidencia(true);
        const defaultData = {};
        const defaultMandatoryData = [];
        formsDGT[event.target.value].forEach(field => {
            defaultData[field.name] = field.type === "select" ? '' : '';
            //defaultData[field.name] = field.type === "select" ? field.options[0] : '';
            if (field.mandatory) {
                defaultMandatoryData.push(field.name)
            }
        });
        defaultMandatoryData.push("País de nacionalidad")
        defaultMandatoryData.push("Provincia cita")
        defaultData["Provincia cita"] = ""
        defaultData["FechaMIN"] = startDate.toLocaleDateString('en-GB')
        defaultData["FechaMAX"] = endDate.toLocaleDateString('en-GB')
        defaultData["txtObservaciones"] = ""
        defaultData["Pais"] = event.target.value
        setFormData(defaultData);
        setmandatoryFormData(defaultMandatoryData);
        if (event.target.value == "Seleccionar...") {
            setAddCita(false);
        } else {
            setAddCita(true);
        }
        setSelectedValue(event.target.value);
    };

    const handleInputChange = (event) => {
        console.log(event)

        setFormData({
            ...formData,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value.trimStart()
        });
    };

    const searchParams = new URLSearchParams(document.location.search)

    const [tipoDoc, setTipoDoc] = React.useState('N.I.E.');

    return (
        <div>
            <div className="form">
                <div className="grid-container">
                    <div key="rdbTipoDoc" className="input-wrapper">
                        <label>Tipo de documento<b style={{color: 'red'}}>{" (*)"}</b></label>
                        <RadioGroup
                            name="rdbTipoDoc"
                            value={tipoDoc}
                            onChange={(newValue) => {
                                setTipoDoc(newValue.target.value)
                                formData["rdbTipoDoc"] = newValue.target.value
                            }}
                        >
                            <FormControlLabel value="N.I.E." control={<Radio/>} label="N.I.E."/>
                            <FormControlLabel value="PASAPORTE" control={<Radio/>} label="PASAPORTE"/>
                            <FormControlLabel value="D.N.I." control={<Radio/>} label="D.N.I."/>
                        </RadioGroup>
                    </div>
                    <div key="txtIdCitado" className="input-wrapper">
                        <label>Número de documento<b style={{color: 'red'}}>{" (*)"}</b></label>
                        <Input
                            name="txtIdCitado"
                            type="text"
                            value={formData["txtIdCitado"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <div key="txtDesCitado" className="input-wrapper">
                        <label>Nombre y apellidos<b style={{color: 'red'}}>{" (*)"}</b></label>
                        <Input
                            name="txtDesCitado"
                            type="text"
                            value={formData["txtDesCitado"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <div key="txtAnnoCitado" className="input-wrapper">
                        <label>Año de nacimiento<b style={{color: 'red'}}>{" (*)"}</b></label>
                        <Input
                            name="txtAnnoCitado"
                            type="number"
                            value={formData["txtAnnoCitado"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <div id="txtPaisNac" className="input-wrapper"
                                     style={{display: showResidencia ? "none" : "flex"}}>
                        <label>País de nacionalidad <b style={{color: 'red'}}> (*)</b></label>
                        <NativeSelectRoot size="sm" width="240px" name="txtPaisNac"
                                          value={selectedProvinceValue} onChange={handleInputChange}>
                            <NativeSelectField name="txtPaisNac">
                                <option key="Seleccionar" value="Seleccionar">Seleccionar</option>
                                {provincias.map(opt => (
                                    <option name="txtPaisNac" key={opt} value={opt}>{opt}</option>
                                ))}
                            </NativeSelectField>
                        </NativeSelectRoot>
                    </div>
                    <div key="txtTelefonoCitado" className="input-wrapper">
                        <label>Teléfono<b style={{color: 'red'}}>{" (*)"}</b></label>
                        <Input
                            name="txtTelefonoCitado"
                            type="tel"
                            value={formData["txtTelefonoCitado"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <div key="txtMailCitado" className="input-wrapper">
                        <label>Correo electrónico<b style={{color: 'red'}}>{" (*)"}</b></label>
                        <Input
                            name="txtMailCitado"
                            type="email"
                            value={formData["txtMailCitado"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <div id="FechaMIN" className="input-wrapper"
                                     style={{display: showResidencia ? "none" : "flex"}}>
                        <label>Fecha minima de la cita</label>
                        <DatePicker
                            showIcon
                            selected={startDate}
                            onSelect={(date) => setStartDate(date)}
                            onChange={(date) => {
                                var event = {
                                    "target": {
                                        "name": "FechaMIN",
                                        "value": date.toLocaleDateString('en-GB'),
                                        "type": "datepicker"
                                    }
                                };
                                handleInputChange(event)
                            }}
                            onChangeRaw={event => {
                                const date = moment(event.target.value, "DD/MM/YYYY");
                                if (date.isValid()) {
                                    setStartDate(date.toDate());
                                }
                            }}
                            name="FechaMIN"
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                        />
                    </div>
                    <div id="FechaMAX" className="input-wrapper"
                                     style={{display: showResidencia ? "none" : "flex"}}>
                        <label>Fecha maxima de la cita</label>
                        <DatePicker
                            showIcon
                            selected={endDate}
                            onSelect={(date) => setEndDate(date)}
                            onChange={(date) => {
                                var event = {
                                    "target": {
                                        "name": "FechaMAX",
                                        "value": date.toLocaleDateString('en-GB'),
                                        "type": "datepicker"
                                    }
                                };
                                handleInputChange(event)
                            }}
                            onChangeRaw={event => {
                                const date = moment(event.target.value, "DD/MM/YYYY");
                                if (date.isValid()) {
                                    setEndDate(date.toDate());
                                }
                            }}
                            name="FechaMAX"
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            locale="es"
                        />
                    </div>
                    <div id="txtObservaciones" className="input-wrapper"
                                     style={{display: showResidencia ? "none" : "flex", paddingBottom: "10px"}}>
                        <label>Observaciones</label>
                        <Input
                            name={"txtObservaciones"}
                            type="text"
                            value={formData["txtObservaciones"] || ''}
                            onChange={handleInputChange}
                            placeholder={""}
                        />
                    </div>
                    <div key="xplanation" className="input-wrapper">
                        <label>La solicitud de esta cita requiere de ser presentada mediante un certificado digital.
                            Deberás subir un fichero de certificado digital en formato .p12 o .pfx junto a su contraseña
                            o
                            el Usuario y Contraseña de inicio de sesión para el sistema Cl@ve permanente.
                            <b>El usuario de esta aplicación actua como único dueño y responsable de estas credenciales
                                aportadas que serán utilizadas por RobotCita Extranjería con el único proposito de
                                confirmar
                                la cita previa</b></label>
                    </div>
                    <div key="ficheroCertificadoDigital" className="input-wrapper">
                        <label>Fichero certificado digital</label>
                        <input type="file" onChange={handleFileChange} name="ficheroCertificadoDigital" accept={".p12, .pfx"}/>
                    </div>
                    <div key="txtPassCertificado" className="input-wrapper">
                        <label>Contraseña fichero certificado digital</label>
                        <Input
                            name="txtPassCertificado"
                            type="text"
                            value={formData["txtPassCertificado"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <div key="txtUsuarioClave" className="input-wrapper">
                        <label>Usuario CL@VE PERMANENTE</label>
                        <Input
                            name="txtUsuarioClave"
                            type="text"
                            value={formData["txtUsuarioClave"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <div key="txtPassClave" className="input-wrapper">
                        <label>Contraseña CL@VE PERMANENTE</label>
                        <Input
                            name="txtPassClave"
                            type="text"
                            value={formData["txtPassClave"] || ''}
                            onChange={handleInputChange}
                            placeholder=''
                        />
                    </div>
                    <Button style={{width: "100%"}} class="sendButton" variant="contained" color="primary"
                                        onClick={handleSubmit}
                    >Enviar solicitud</Button>
                </div>
                {allMandatoryFilled && <p>Rellena todos los campos obligatorios (*)</p>}
                <div></div>
            </div>
        </div>
    );
}

export default App;
