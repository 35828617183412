import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "./components/ui/provider"

import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";
import Error from "./ErrorDisplay";
import Success from "./SuccessDisplay";

const router = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/" element={<App />}>
            {/* ... etc. */}
        </Route>, <Route path="error" element={<Error/>} />
        , <Route path="success" element={<Success/>} />]
    )
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <title>Formulario ICP Clave</title>
        <Provider>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
