const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 9.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

const options = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder"
];

const verificaNIF = (nie) => {
    let numero;

    nie = nie.toUpperCase();

    const letrasControl = "TRWAGMYFPDXBNJZSQVHLCKE";
    const regexNIE = /^[XYZ]?\d{7,8}[A-Z]$/;

    if (!regexNIE.test(nie)) {
        return false;
    }
    let letraInicial = nie.charAt(0);

    switch (letraInicial) {
        case 'X':
            numero = '0';
            break;
        case 'Y':
            numero = '1';
            break;
        case 'Z':
            numero = '2';
            break;
        default:
            numero = letraInicial;
            break;
    }

    let numero2 = nie.substring(1, 8);
    let letraControl = nie.charAt(8);

    numero = numero + numero2;

    const letraCalculada = letrasControl.charAt(parseInt(numero, 10) % 23);

    return letraControl === letraCalculada;
}

export { MenuProps, options, verificaNIF };
