import './App.css';
import formsDGT from './forms_dgt.json';
import provincias from './provincias.json';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {Checkbox, Input, MenuItem, Select, Button, NativeSelectRoot, NativeSelectField} from "@chakra-ui/react";
import * as React from 'react'

export default function Error() {
    return (
        <div>
            <h1>Hubo un error al enviar la información, completa el proceso de nuevo.</h1>
        </div>
    );
};